<template>
<div class="layout-container webflow-card">
  <b-row class="p-0 m-0 pl-1" :key="update">
    <b-col v-for="(item, index) in vmEventlist" :key="index" class="mobileNoPadding col-12 col-sm-6 col-md-6 col-lg-4">
      <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height">
        <div class="event-images">
          <a style="cursor: pointer;" @click="eventDetail(item.event_id)">
            <img v-if="item.event_img" :src="item.event_img" style="" class="img-fluid image image_eventpost">
              <div v-else class="image" style="display: flex; text-align: center; background-color: #EEEEEE; border-radius: 5px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
                <span style="margin: auto; font-size: 111px;">{{item.event_name.slice(0,1)}}</span>
              </div>
          </a>
        </div>
        <div class="row blog-meta align-items-center mb-3">
          <div class="col-4 col-sm-5 col-md-4 col-lg-4 date ">
        <div v-if="item.event_master_type == 'Gide+'" style="margin-left:20px">
          <b-badge class="border mt-2 font-weight-normal" variant="primary"
            style="font-size: 11px; font-weight: bold !important;"> {{ item.event_master_type }}
          </b-badge>
        </div>
        <div v-else style="margin-left:20px">&nbsp;
        </div>
          </div>
          <div class="col-8 col-sm-7 col-md-8 col-lg-8 rating_comments">
            <div v-for="index in 5" :key="index" class="rating ratingInput">
              <label v-if="isPastEvent && index <= item.rating" class="selected">☆</label>
              <label v-else class="unselected">☆</label>
            </div>
          </div>
        </div>
        <b-card-body class="iq-card-body rsvpCardBody pt-2" @click="eventDetail(item.event_id)">
          <div class="webKitBox">
            <div class="events-detail ml-1 w-100" style="cursor: pointer;">
              <h4 class="twoLineOnly pr-3 mb-1">
                {{ item.event_name }}
              </h4>
            </div>
            <div class="date-of-event ml-1">
              <h6>
                Start Time: <span>{{ item.event_start_ts | dateFormatDDMMYYYYHHMMA}}</span>
              </h6>
            </div>
            <div class="date-of-event ml-1 mt-1">
              <h6>
                Duration: <span>{{ item.event_duration | durationToDHM }}</span>
              </h6>
            </div>
          </div>
        </b-card-body>
        <b-card-footer style="background-color:#fff">
          <b-row>
            <b-col col-11>
              <p>Registrations: <span>{{ item.event_total_regs }}</span></p>
            </b-col>
            <b-col col-1>
              <button v-if="isPastEvent" class="btn btn-sm mr-2 btn-primary float-right" disabled>
                Register
              </button>
              <button v-else class="btn btn-sm mr-2 btn-primary float-right" @click="openUrlInNewTab(`/event/${item.event_id}`)">
                Register
              </button>
            </b-col>
          </b-row>
        </b-card-footer>
      </iq-card>
    </b-col>

    <b-col lg="12" class="text-center pt-3">
      <div v-if="!allLoaded" v-infinite-scroll="getEventList" :infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <b-spinner variant="primary" label="Spinning" >
        </b-spinner>
      </div>
    </b-col>
    <b-col lg="12" class="text-center pt-3" v-if="!vmEventlist.length && allLoaded">
      <div class="text-center">
        Watch out this space for more events
      </div>
    </b-col>
  </b-row>
</div>
</template>
<script>
import { socialvue } from "../../config/pluginInit"
import { events } from "../../FackApi/api/events.js"

export default {
  name: "GideEventList",
  props: {
  },
  components: {
  },
  data () {
    return {
      vmEventlist: [],
      limit: 10,
      offset: 0,
      allLoaded: false,
      busy: false,
      update: 0,
      isPastEvent: false
    }
  },
  mounted () {
    socialvue.index()
  },
  watch: {
    "$route.query" () {
      this.vmEventlist = []
      this.getEventList(this.$route.query)
    }
  },
  methods: {
    /**
     * openUrlInNewTab
     */
    openUrlInNewTab (url) {
      window.open(url, "_blank")
    },
    /**
     * getEventList
     */
    async getEventList (routeQuery = this.$route.query) {
      this.busy = true
      try {
        let filter = {
          limit: this.limit,
          offset: this.offset
        }

        if (routeQuery.past_event) {
          filter.past_events = true
          this.isPastEvent = true
        }
        else {
          filter.upcoming_events = true
          this.isPastEvent = false
        }

        if (this.$route.meta.name === "GideUniEvents") {
          filter.user_role = "USERROLE11115"
        }

        const eventResp = await events.eventListPublic(this, filter)
        if (eventResp.resp_status) {
          if (eventResp.resp_data.data.length !== this.limit) {
            this.allLoaded = true
          }

          this.vmEventlist = [...this.vmEventlist, ...eventResp.resp_data.data]
          this.offset += this.limit
        }
        else {
          this.allLoaded = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
      finally {
        this.busy = false
      }
    },
    /**
     * eventDetail
     */
    eventDetail (eventId) {
      let url = `GideEvent/${eventId}`

      if (this.$route.path.includes("GideEventsV1")) {
        url = `GideEventV1/${eventId}`
      }

      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-container {
  max-width: 1210px;
  margin: auto;
  padding: 0px;
}
.webKitBox {
  display: block;
}
.image.image_eventpost{
  width: 100%;
  height: 200px !important;
}
.rating_comments{
  justify-content: right;
  right: 20px;
}
@media (max-width : 767px){
  .rating > label {
    top: -3px;
  }
}
</style>
